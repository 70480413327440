@charset "utf-8";
@import "/css/bootstrap/bootstrap.min.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');

$enable-rounded:   false;
// Define defaults for each variable.

$brand-primary:         #87C544 !default;//darken(#428bca, 6.5%) !default; // #337ab7
$brand-success:         #5cb85c !default;
$brand-info:            #5bc0de !default;
$brand-warning:         #f0ad4e !default;
$brand-danger:          #d9534f !default;

$base-font-family: "Roboto", sans-serif !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$text-color:       #111 !default;
$background-color: #fdfdfd !default;
$brand-color:      #2a7ae2 !default;
//$teacher-exclusive-color: hsl(294, 37%, 85%) !default;
//$teacher-exclusive-color: #FFDFD3 !default;
//$teacher-exclusive-color: #E9D3FF !default;
$teacher-exclusive-color-active: #87C544 !default;
$teacher-exclusive-color: #B9DD92 !default;

$lo-color: #ffffff !default;
$lo-color-active: #87C544 !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

$neutral-background: #F6FAF2;

// Width of the content area
$content-width:    1000px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }

/* Allow for print-friendly version of learning object page */
@media print {
  body {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
    font-size: 14pt !important;
  }
  // Set page break after each section
  section.lp-section {
    page-break-after: always!important;
  }

  @page {
    size: A4;
    margin: 1.5cm;
  }

  .title-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40%;
    page-break-after: always;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
  }

  .author {
    font-size: 18px;
    margin-top: 10px;
  }
}


html { 
  overflow-x: hidden; 
}

body {
  overflow-x: hidden;
  font-family: "Roboto" sans-serif !important;
}


.wrapper {
  max-width: -webkit-calc(1300px - (15px * 2))!important;
  max-width: calc(1300px - (15px * 2))!important;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

.values {
  padding-left: 1rem;
  padding-right: 1rem;
}

.team-member {
  width: 100%;
  padding: 1rem;
}

img.team-member {
  object-fit: contain; /* Scale the image to contain the foreseen box */
  object-position: center; /* Center the image within the element */
  width: 100%;
  max-height: 191px; /* Because some team member photos have a smaller height */
}

.simulator {
  background-color: $brand-primary;
  color: $lo-color;
  margin-left: 0.5rem;
}

.header_button_container{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

a.simulator{
  color: $lo-color !important;
}

a.simulator:hover{
  color: $text-color !important;
} 

a.footer{
  color: $text-color !important;
}

a.footer:hover{
  color: $text-color !important;
} 

.lighten {
 color: $lo-color;
}

.social {
  width: 40px;
  padding: 5px;
}

.bg-image {
  background-image: url("../images/about/map.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: contain;
  background-color: #3B3B3B;
  margin: 0 -100rem;
  /* add back negative margin value */
  padding: 0.25rem 100rem;
}

.mission {
  background-color: rgba(2,2,2,0.3);
  border-radius: 15px;
}

.mission h3{
  font-size: 38px;
  font-weight: 300;
  text-align: center;
}

.mission h4{
  font-weight: 300;
  text-align: center;
}

.mission p{
  text-align: center;
  letter-spacing: 2px;
}

.commitment h3{
  font-weight: 300;
  text-align: center;
}

.commitment p{
  text-align: center;
}

.number-circle {
  margin: auto;
  justify-content: center;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 8px;

  background: $brand-primary;
  border: 2px solid $brand-primary;
  color: $lo-color;
  text-align: center;
}

.team h3{
  text-align: center;
}

.team p{
  text-align: center;
}

.p-counting {
  font-size: 48px;
}

// Responsive iframe
.iframe-container {
  overflow: hidden;
  position: relative;
}

.iframe-16-9 {
  /* 16:9 aspect ratio */
  padding-top: 56.25%;
}

.iframe-1-1 {
  padding-top: 100%;
}

.iframe-1-2 {
  padding-top: 200%;
}

.iframe-container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}

.button_lp:hover {
  cursor: pointer;
}

.button_lp {
  border: 1px solid transparent;
  background-color: $lo-color;
  text-align: left;
  text-decoration: underline;
  font-style: italic;
  padding-left: 0px!important;
  font-size: smaller;
}

#lp_visualisation .list-group-item {
  background-color: $lo-color;
  border: 1px solid #ced4da;
  cursor: pointer;
}

/*#lp_visualisation .list-group-item.active {
  background-color: $lo-color-active;
  border: 1px solid #ced4da;
  cursor: pointer;
}*/

#lp_visualisation .list-group-item.active {
  color: black;
  //font-weight: bold;
  border: 1px solid #ced4da;
  cursor: pointer;
}


#lp_visualisation .list-group-item.teacher_exclusive {
  background-color: $teacher-exclusive-color;
  border: 1px solid #ced4da;
  cursor: pointer;
}

.green_box_info {
  display:flex;
  font-style: italic;
  font-size: small;
}

.green_box {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: $teacher-exclusive-color;
  border: 1px solid #ced4da;
  align-items: baseline;
  margin-right: 10px;
}

.white_box_info {
  display:flex;
  font-style: italic;
  font-size: small;
}

.white_box {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #ced4da;
  align-items: center;
  margin-right: 10px;
}

#lp_visualisation .list-group-item.active span:first-of-type {
  border-left: solid black 4px;
  padding-left: 10px;
}

.legend {
  margin-bottom: 10px;
  margin-top: 10px;
}


/*#lp_visualisation .list-group-item.teacher_exclusive.active {
  background-color: $teacher-exclusive-color-active;
  border: 1px solid #ced4da;
  cursor: pointer;
}*/

#lp_visualisation .list-group-item.teacher_exclusive.active {
  background-color: $teacher-exclusive-color;
  color:black;
  border: 1px solid #ced4da;
  //font-weight: bold;
  cursor: pointer;
}

#lp_visualisation .list-group-item span:first-of-type{
  display: inline-block;
  max-width: 86%;
  word-break: break-word;
}


// Fill out text in learning objects
#lo_content p{
  text-align: justify;
  text-justify: inter-word;
}


/*
#learning_paths .card-title {
  height: 20%;
  box-sizing: border-box;
}

#learning_paths .card-text {
  height: 75%;
  box-sizing: border-box;
  overflow: hidden;
  line-clamp: 7;
}

#learning_paths .card-body {
  padding: 15px;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

#learning_paths .card-body-container {
  overflow: hidden;
  position: relative;
  padding-top: 100%;
}

#learning_paths .card-img-top {
  position: relative;
  top: 0;
}*/



.blocklyDiv {
  width: 100%;
}

img.values {
  max-width: 75%;
}

.strengths_container{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.strength-container {
  text-align: center;

}

.research_component{
  max-width: 130px;
}

/*.keyword {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border: solid #ced4da 1px;
  border-radius: 0.25rem;
  display: inline-block;
}*/

/*#keyword-container {
  padding: 1em;
}*/


.keyword:hover {
  cursor: pointer;
}

.slider-container {
  margin-top: 0.5rem;
}

.age-label{
  padding-left: 1rem;
  padding-right: 1rem;
}

.lo_content img {
  width: auto;
}

img {
  //width: 100%;
  height: auto;
}

#dwengo_logo_image{
  max-width: 150px;
}

// .card-body-container{
//   border-bottom-left-radius: 0.25rem;
//   border-bottom-right-radius: 0.25rem;
// }

// .card-img-top{
//   border-top-left-radius: 0.25rem;
//   border-top-right-radius: 0.25rem;
// }


// Styles for AI Op School banner
.frontpage_banner {
  height: 100%;
  width: 100vw;
  right: 0px;
  background-position: top right;
  z-index: -1;
  //background-position-y: -230px;
}


@media (min-width: 1200px){
  .home_info_pane {
    margin-top: 180px;
  }
  .frontpage_banner{
    max-height: 550px;
    background-attachment: fixed;
    background-size: cover;
    margin-top: -30px;
    position: absolute;
    top: 0px;
    
  }

  .frontpage_frontpage_banner{
    background-position-y: -230px;
  }

  .ct_algorithm_container,  .ct_abstraction_container, .ct_decomposition_container, .ct_pattern_recognition_container {
    width: calc(50% + 0.5px);
    padding: 15px;
    min-width: 318px;
    border: 1px solid black;
    margin-top: -1px;
    margin-left: -1px;
    position: relative;
  }
}


@media (min-width: 768px) and (max-width: 1200px){
  .home_info_pane {
    margin-top: 180px;
  }
  .frontpage_banner{
    max-height: 400px;
    background-attachment: fixed;
    background-size: 100vw auto;
    margin-top: -30px;
    position: absolute;
    top: 0px;
  }

  .ct_algorithm_container,  .ct_abstraction_container, .ct_decomposition_container, .ct_pattern_recognition_container {
    width: 100%;
    padding: 15px;
    min-width: 318px;
    border: 1px solid black;
    margin-top: -1px;
    margin-left: -1px;
    position: relative;
  }
}

@media (max-width:768px){
  .home_info_pane {
    margin-top: 115px;
  }
  .frontpage_banner{
    max-height: 250px;
    background-attachment: local;
    background-size: cover;
    position: absolute;
    top: 77px;
  }

  .ct_algorithm_container,  .ct_abstraction_container, .ct_decomposition_container, .ct_pattern_recognition_container {
    width: 100%;
    padding: 15px;
    min-width: 318px;
    border: 1px solid black;
    margin-top: -1px;
    margin-left: -1px;
    position: relative;
  }
}

.home_info_pane {
  margin-bottom:20px;
  padding: 20px;
}

.home_info_pane_video {
  padding: 0 !important;
}

.catchphrase-first p:first-of-type {
  font-weight: 550;
  font-size: 1.25rem;
}

.kiks-logo-right{
  max-width: 90px;
  max-height: 80px;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.site-header{
  border-top: none !important;
}

footer {
  background-color: $neutral-background;
}

.flex-container-partners {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.partner_logo{
  max-width: 140px;
  min-width: 140px;
  margin-top: 48px;
  margin-right: 48px;
}

.fp_subtitle{
  margin-top: 2rem;
  margin-bottom: 1rem;
}

#lp_section {
  margin-bottom: 2rem;
}

.curricula_files{
  background-color:$neutral-background;
  color: #757575 !important;
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 15px;
}

.curricula_files a {
  color: #757575 !important;
}

.curricula_files a:hover {
  color: #757575 !important;
}

.curricula_files a:visited {
  color: #757575 !important;
}

.curricula_files .cc_by_info {
  font-weight: bolder;
}

.curricula_files .file_download_info {
 display: flex;
 flex-direction: row; 
 justify-content: space-between;
 margin: 20px 0;
 align-items: top;
}

.curricula_files .file_download_info_text{
  display: flex;
  flex-direction: row; 
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: baseline;
  margin-bottom: 20px;
  width: 100%;
}


.file_icon {
  order: 1;
}

.file_title {
  font-weight: bolder;
}

.file_info {
  flex-basis: 100%;
  font-size: small !important;
}

.file_link {
  border: 1px solid #757575;
  border-radius: 5px;
  padding: 3px 10px;
  background-color: $neutral-background;
  color: #757575;
}

.flex_break{
  flex-basis: 100%;
  height: 10px;
}

.cc_by_logo {
  margin-left: 10px;
  margin-right: 10px;
}

.icon_container {
  margin-right: 25px;
  color: white;
}


.icon_container span {
 font-size: 25px; 
 padding: 15px;
 background-color: #616161;
 border-radius: 50%;
}

// CSS for ct-schema learning object

.ct_schema_container {
  display: flex;
  flex-direction: column;
}

.ct_row1_container, .ct_row2_container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
}

.ct_context_container, .ct_implementation_container{
  width: 100%;
  border: 1px solid black;
  padding: 15px;
  margin-top: -1px;
  margin-left: -1px;
  position: relative;
}

.ct_logo {
  width: 30px;
  height:30px;
  position:absolute;
  bottom: 0px;
  right: 0px;
  margin: 5px;
}

.nav-item.active{
  background-color: $brand-primary;
  border-radius: 0.25rem;
}

.nav-link.active {
  color: white !important;
}

.page_wide_info{
  background-color: #F6FAF2;
  padding: 24px 48px;
}

/* Style this div to fill its container, have a yellow background and border.*/
.book_us{
  margin-top: 3rem;
  background-color: #ffecb3;
  color: black;
  padding: 24px 48px;
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;

.loading_spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid $brand-primary;
  border-bottom: 16px solid #F6FAF2;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: absolute;
  left: calc(50% - 60px);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.teaser_container {
  // Centering text and positioning the container as a circle
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffdd04ff;
  border-radius: 50%;
  width: 200px;   // Adjust this value for the circle's size
  height: 200px;  // Width and height are the same for a perfect circle
  text-align: center;
  margin: auto;
  box-shadow: 9px 20px 4px 0px rgba(0, 0, 0, 0.3);  // Adds a soft circular shadow

  // Responsive positioning
  @media (min-width: 1200px) {
    position: absolute;
    top: 377px;      // Adjust the position as desired
    right: calc(50% - 440px);
  }
  @media (max-width: 1199px) {
    position: relative;
    margin: 10px auto 60px auto;  // Centers the circle on smaller screens
  }

  h2 {
    margin: 0;
    font-size: 1.5em;
    
    a {
      text-decoration: none;
      color: inherit; // This makes the link color the same as the parent container’s color
    }
  }
}